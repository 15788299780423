import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPerformanceList } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/performance/getData', async datas => {
    const { data } = await apiPerformanceList(datas)
    return data
})

export const performanceSlice = createSlice({
    name: 'admin/report/performance',
    initialState: {
        loading: true,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
    },
})

export const { setData, setTableData } = performanceSlice.actions

export default performanceSlice.reducer
