import { AiFillProject } from 'react-icons/ai'
import { LuLayoutDashboard } from 'react-icons/lu'
import { FaTruckFast } from 'react-icons/fa6'
import { GrContact, GrDriveCage } from 'react-icons/gr'
import { BiSolidShoppingBags } from 'react-icons/bi'
import { VscServerProcess } from 'react-icons/vsc'
import { FaTruck } from 'react-icons/fa'
import { FiShoppingBag } from 'react-icons/fi'
import { IoBagCheck } from 'react-icons/io5'
import { MdOutlineFileUpload } from 'react-icons/md'

const appRoutes = [
    {
        text: 'Menu',
        child: [
            {
                url: '/user',
                text: 'Dashboard',
                permission: 'dashboard',
                icon: <LuLayoutDashboard />,
            },
            {
                active: ['/user/collector-collection-points'],
                url: '/user/collector-collection-points',
                text: 'Collector Request',
                permission: 'collector_collection_points',
                icon: <AiFillProject />,
            },
            {
                active: ['/user/projects'],
                url: '/user/projects',
                text: 'Projects',
                permission: 'projects',
                icon: <AiFillProject />,
            },
            {
                active: ['/user/waste-drops'],
                url: '/user/waste-drops',
                text: 'Waste Drops',
                permission: 'waste_drops',
                icon: <FaTruckFast />,
            },
            {
                active: ['/user/cages'],
                url: '/user/cages',
                text: 'ResiLoop Box',
                permission: 'cages',
                icon: <GrDriveCage />,
            },
            {
                active: ['/user/bulko-bags'],
                url: '/user/bulko-bags',
                text: 'ResiLoop Bin',
                permission: 'bulko_bags',
                icon: <BiSolidShoppingBags />,
            },
            {
                active: ['/user/pickups'],
                url: '/user/pickups',
                text: 'Pickup',
                permission: 'pickup.index',
                icon: <FaTruck />,
            },
            {
                active: ['/user/batches'],
                url: '/user/batches',
                text: 'Batches',
                permission: 'batchs',
                icon: <VscServerProcess />,
            },
            {
                active: ['/user/collector-bag-requests'],
                url: '/user/collector-bag-requests',
                text: 'Collector ResiLoop Bag Requests',
                permission: 'collector_bag_requests',
                icon: <FiShoppingBag />,
            },
            {
                active: ['/user/bag-requests'],
                url: '/user/bag-requests',
                text: 'ResiLoop Bag Requests',
                permission: 'bag_requests',
                icon: <IoBagCheck />,
            },
            {
                active: ['/user/file-uploads'],
                url: '/user/file-uploads',
                text: 'Resources',
                permission: 'file_uploads',
                icon: <MdOutlineFileUpload />,
            },
            {
                active: ['/user/contacts'],
                url: '/user/contacts',
                text: 'Contacts',
                permission: 'contacts',
                icon: <GrContact />,
            },
        ],
    },
]

export default appRoutes
