import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, Select } from 'Components/Form'

export const dataDefault = {
    bulko_bag_id: '',
}

export default function BulkoBag({ index, datas, data, setFieldValue, touched, errors, bulko_bags }) {
    var name = `pickup_bulko_bag[${index}].`

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
                <Select
                    id={`${name}bulko_bag_id`}
                    name={`${name}bulko_bag_id`}
                    label={index === 0 ? 'ResiLoop Bin' : null}
                    placeholder="ResiLoop Bin"
                    value={data.bulko_bag_id}
                    options={bulko_bags
                        .filter(e => e.total_bags > 0)
                        .map(e => ({
                            id: e.id,
                            label: e.number + ' (' + e.total_bags + ' BG)',
                        }))}
                    onChange={e => setFieldValue(`${name}bulko_bag_id`, e.target.value)}
                    error={touched?.bulko_bag_id && errors?.bulko_bag_id}
                    className="col-span-3 sm:col-span-2"
                />
                <div>
                    <div className={`flex items-center gap-3 ${index === 0 ? 'pt-7' : null}`}>
                        {index + 1 === datas.length ? (
                            <Button
                                type="button"
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    saveTask.push(dataDefault)
                                    setFieldValue('pickup_bulko_bag', saveTask)
                                }}
                            >
                                <AiOutlinePlus size={20} />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="text-white border-danger bg-danger"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    remove(saveTask, (n, key) => key === index)
                                    setFieldValue('pickup_bulko_bag', saveTask)
                                }}
                            >
                                <AiOutlineClose size={20} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
