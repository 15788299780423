export const lengthOption = [
    {
        id: 10,
        label: 10,
    },
    {
        id: 25,
        label: 25,
    },
    {
        id: 50,
        label: 50,
    },
    {
        id: 100,
        label: 100,
    },
]
