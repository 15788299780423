import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, FileUpload, Select, TextInput } from 'Components/Form'

export const dataDefault = {
    bulko_bag_id: '',
    accept_bags: '',
    batch_reject_type_id: '',
    reject_type: '',
    accept_weight: '',
    reject_bags: '',
    reject_weight: '',
    image: '',
    images: '',
    notes: '',
}

export default function BulkoBag({ index, datas, data, setFieldValue, touched, errors, bulko_bags, batchRejectTypes }) {
    var name = `batch_bulko_bag[${index}].`

    var bulkoBag = bulko_bags.find(f => f.id === data.bulko_bag_id)

    return (
        <div className="grid grid-cols-1 gap-3 pt-3 border-t md:grid-cols-11 border-stroke">
            <div className="flex flex-col col-span-10 gap-3">
                <div className="grid grid-cols-2 gap-3 md:grid-cols-6">
                    <Select
                        id={`${name}bulko_bag_id`}
                        name={`${name}bulko_bag_id`}
                        placeholder="ResiLoop Bin"
                        value={data.bulko_bag_id}
                        options={bulko_bags.map(e => ({
                            id: e.id,
                            label: e.number,
                        }))}
                        onChange={e => {
                            setFieldValue(`${name}bulko_bag_id`, e.target.value)
                            var bulko_bag = bulko_bags.find(f => f.id === e.target.value)

                            setFieldValue(`${name}accept_bags`, bulko_bag?.total_bags ?? 0)
                            setFieldValue(`${name}accept_weight`, bulko_bag?.total_weight ?? 0)
                            setFieldValue(`${name}reject_bags`, 0)
                            setFieldValue(`${name}reject_weight`, 0)

                            setFieldValue(`${name}batch_reject_type_id`, '')
                            setFieldValue(`${name}reject_type`, '')
                            setFieldValue(`${name}images`, '')
                            setFieldValue(`${name}notes`, '')
                        }}
                        error={touched?.bulko_bag_id && errors?.bulko_bag_id}
                        className="col-span-2 sm:col-span-2"
                    />
                    <TextInput
                        type="number"
                        id={`${name}accept_bags`}
                        name={`${name}accept_bags`}
                        placeholder="Accept Bags"
                        value={data.accept_bags}
                        error={touched?.accept_bags && errors?.accept_bags}
                        min="1"
                        max={bulkoBag?.total_bags ?? 0}
                        className="col-span-1"
                        onChange={e => {
                            var reject_bags = 0
                            if (bulkoBag) {
                                reject_bags = (bulkoBag.total_bags ?? 0) - e.target.value
                            }
                            setFieldValue(`${name}accept_bags`, e.target.value)
                            setFieldValue(`${name}reject_bags`, reject_bags)
                        }}
                    />
                    <TextInput
                        type="number"
                        id={`${name}accept_weight`}
                        name={`${name}accept_weight`}
                        placeholder="Accept Weight"
                        value={data.accept_weight}
                        error={touched?.accept_weight && errors?.accept_weight}
                        min="1"
                        max={bulkoBag?.total_weight ?? 0}
                        className="col-span-1"
                        icon="KG"
                        onChange={e => {
                            var reject_weight = 0
                            if (bulkoBag) {
                                reject_weight = (bulkoBag.total_weight ?? 0) - e.target.value
                            }
                            setFieldValue(`${name}accept_weight`, e.target.value)
                            setFieldValue(`${name}reject_weight`, reject_weight)
                        }}
                    />
                    <TextInput
                        type="number"
                        id={`${name}reject_bags`}
                        name={`${name}reject_bags`}
                        placeholder="Reject Bags"
                        value={data.reject_bags}
                        error={touched?.reject_bags && errors?.reject_bags}
                        min="1"
                        className="col-span-1"
                        disabled
                    />
                    <TextInput
                        type="number"
                        id={`${name}reject_weight`}
                        name={`${name}reject_weight`}
                        placeholder="Reject Weight"
                        value={data.reject_weight}
                        error={touched?.reject_weight && errors?.reject_weight}
                        min="1"
                        className="col-span-1"
                        icon="KG"
                        disabled
                    />
                </div>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-8">
                    <Select
                        id={`${name}batch_reject_type_id`}
                        name={`${name}batch_reject_type_id`}
                        placeholder="Batch Reject Type"
                        value={data.batch_reject_type_id}
                        options={batchRejectTypes.map(e => ({
                            id: e.id,
                            label: e.name,
                        }))}
                        onChange={e => {
                            setFieldValue(`${name}batch_reject_type_id`, e.target.value)
                        }}
                        error={touched?.batch_reject_type_id && errors?.batch_reject_type_id}
                        className="col-span-3 sm:col-span-2"
                        disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                    />
                    {data.batch_reject_type_id === 'others' ? (
                        <TextInput
                            id={`${name}reject_type`}
                            name={`${name}reject_type`}
                            placeholder="Reject Type"
                            value={data.reject_type}
                            error={touched?.reject_type && errors?.reject_type}
                            className="col-span-3 sm:col-span-2"
                            disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                        />
                    ) : null}
                    <div className="col-span-3 sm:col-span-2">
                        <FileUpload
                            id={`${name}images`}
                            name={`${name}images`}
                            placeholder="Images"
                            error={touched?.images && errors?.images}
                            disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                            onChange={e => setFieldValue(`${name}images`, e.currentTarget.files[0])}
                        />
                        {data.image_url ? (
                            <a
                                href={data.image_url + '?download=' + data.image}
                                target="_blank"
                                rel="noreferrer"
                                className="cursor-pointer"
                            >
                                <p className="text-sm text-black dark:text-white hover:text-primary dark:hover:text-secondary">
                                    Download
                                </p>
                            </a>
                        ) : null}
                    </div>
                    <TextInput
                        id={`${name}notes`}
                        name={`${name}notes`}
                        placeholder="Notes"
                        value={data.notes}
                        error={touched?.notes && errors?.notes}
                        className="col-span-3 sm:col-span-2"
                        disabled={!((data.reject_bags ?? 0) > 0 || (data.reject_weight ?? 0) > 0)}
                    />
                </div>
            </div>

            <div className="flex flex-row gap-2 md:flex-col">
                {index + 1 === datas.length ? (
                    <Button
                        type="button"
                        className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                        onClick={() => {
                            var saveTask = clone(datas)
                            saveTask.push(dataDefault)
                            setFieldValue('batch_bulko_bag', saveTask)
                        }}
                    >
                        <AiOutlinePlus size={20} />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        className="text-white border-danger bg-danger"
                        onClick={() => {
                            var saveTask = clone(datas)
                            remove(saveTask, (n, key) => key === index)
                            setFieldValue('batch_bulko_bag', saveTask)
                        }}
                    >
                        <AiOutlineClose size={20} />
                    </Button>
                )}
            </div>
        </div>
    )
}
