import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiCollectionPointsList, apiCollectionPointsView } from 'Services/AdminService'

export const getData = createAsyncThunk('admin/collectionPoints/getData', async datas => {
    const { data } = await apiCollectionPointsList(datas)
    return data
})

export const getView = createAsyncThunk('admin/collectionPoints/getView', async id => {
    if (!id) return viewData
    const { data } = await apiCollectionPointsView(id)
    data.phone = data.phone ?? ''
    data.logo = ''
    data.images = []
    data.image_remove = []
    return data
})

const viewData = {
    business_name: '',
    address: '',
    phone: '',
    type: '',
    pickup_provide: '0',
    logo: '',
    images: [],
    image_remove: [],
    notes: '',
}

export const collectionPointsSlice = createSlice({
    name: 'admin/collectionPoints',
    initialState: {
        loading: true,
        view: viewData,
        data: [],
        total: 0,
        table: {
            page: 1,
            length: 10,
            search: '',
            column: '',
            dir: '',
        },
    },
    reducers: {
        setData: (state, { payload }) => {
            state.data = payload
        },
        setTableData: (state, { payload }) => {
            state.table = payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getData.pending, state => {
                state.loading = true
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                state.loading = false
                state.data = payload.data
                state.total = payload.total
            })
            .addCase(getData.rejected, state => {
                state.loading = false
                state.data = []
                state.total = 0
            })
            .addCase(getView.pending, state => {
                state.loading = true
                state.view = viewData
            })
            .addCase(getView.fulfilled, (state, { payload }) => {
                state.loading = false
                state.view = payload
            })
    },
})

export const { setData, setTableData } = collectionPointsSlice.actions

export default collectionPointsSlice.reducer
