import { HiOutlineBell } from 'react-icons/hi2'
import moment from 'moment/moment'

import { Dropdown } from 'Components/Ui'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getNotificationData, readNotificationData } from 'Store/auth/notificationSlice'

const Notifications = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const notifications = useSelector(state => state.notification)

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        if (!user || !user.guard) return
        dispatch(getNotificationData(user.guard))
    }

    const readNotification = () => {
        if (notifications.length === 0) return
        if (!user || !user.guard) return
        dispatch(readNotificationData(user.guard))
    }
    return (
        <>
            <Dropdown>
                <Dropdown.Trigger downShow={false}>
                    <label
                        onClick={readNotification}
                        className="relative flex items-center justify-center w-8 h-8 border rounded-full border-stroke bg-gray hover:text-primary dark:hover:text-secondary dark:border-strokedark dark:bg-graydark dark:text-white"
                    >
                        {notifications.length !== 0 && (
                            <span className="absolute -top-0.5 right-0 h-2 w-2 rounded-full bg-danger">
                                <span className="absolute right-0 inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-danger"></span>
                            </span>
                        )}
                        <HiOutlineBell size={20} />
                    </label>
                </Dropdown.Trigger>
                <Dropdown.Content width="80" contentClasses="max-h-96" className="-right-20 sm:right-0">
                    <div className="px-4.5 py-3">
                        <h5 className="text-sm font-bold ">Notification</h5>
                    </div>
                    <ul className="flex flex-col h-auto overflow-y-auto">
                        {notifications.map((row, index) => (
                            <li key={index}>
                                <div className="flex flex-col gap-2.5 border-t border-stroke px-4 py-3 hover:bg-gray dark:border-strokedark dark:hover:bg-graydark">
                                    <p className="text-sm">{row.data.message}</p>
                                    <p className="text-xs">{moment(row.created_at).fromNow()}</p>
                                </div>
                            </li>
                        ))}
                        {notifications.length === 0 && (
                            <li>
                                <div className="border-t border-stroke px-4.5 py-3  dark:border-strokedark items-center text-center text-primary dark:text-white">
                                    Data not found
                                </div>
                            </li>
                        )}
                    </ul>
                </Dropdown.Content>
            </Dropdown>
        </>
    )
}

export default Notifications
