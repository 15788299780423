import { clone, remove } from 'lodash'
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai'

import { Button, Select, TextInput } from 'Components/Form'

export const dataDefault = {
    material_id: '',
    bag_type_id: '',
    bags_count: '',
    comment: '',
}

export default function BagRequests({
    index,
    datas,
    data,
    setFieldValue,
    touched,
    errors,
    materials,
    bag_type = false,
    bag_types,
}) {
    var name = `material_type[${index}].`

    return (
        <div className="flex flex-col gap-3">
            <div className={`grid grid-cols-3 gap-3 ${bag_type ? 'sm:grid-cols-9' : 'sm:grid-cols-7'}`}>
                <Select
                    id={`${name}material_id`}
                    name={`${name}material_id`}
                    label={index === 0 ? 'Material' : null}
                    placeholder="Material"
                    value={data.material_id}
                    options={materials.map(e => ({
                        id: e.id,
                        label: e.title,
                    }))}
                    onChange={e => setFieldValue(`${name}material_id`, e.target.value)}
                    error={touched?.material_id && errors?.material_id}
                    className="col-span-3 sm:col-span-2"
                />
                {bag_type ? (
                    <Select
                        id={`${name}bag_type_id`}
                        name={`${name}bag_type_id`}
                        label={index === 0 ? 'Bag type' : null}
                        placeholder="Bag type"
                        value={data.bag_type_id}
                        options={bag_types.map(e => ({
                            id: e.id,
                            label: e.name,
                        }))}
                        onChange={e => setFieldValue(`${name}bag_type_id`, e.target.value)}
                        error={touched?.bag_type_id && errors?.bag_type_id}
                        className="col-span-3 sm:col-span-2"
                    />
                ) : null}
                <TextInput
                    type="number"
                    id={`${name}bags_count`}
                    name={`${name}bags_count`}
                    label={index === 0 ? 'Bags Count' : null}
                    placeholder="Bags Count"
                    error={touched?.bags_count && errors?.bags_count}
                    min="1"
                    className="col-span-3 sm:col-span-2"
                />
                <TextInput
                    id={`${name}comment`}
                    name={`${name}comment`}
                    label={index === 0 ? 'Comment' : null}
                    placeholder="Comment"
                    error={touched?.comment && errors?.comment}
                    className="col-span-2 sm:col-span-2"
                />
                <div>
                    <div className={`flex items-center gap-3 ${index === 0 ? 'pt-7' : null}`}>
                        {index + 1 === datas.length ? (
                            <Button
                                type="button"
                                className="text-white border-primary bg-primary dark:border-secondary dark:bg-secondary"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    saveTask.push(dataDefault)
                                    setFieldValue('material_type', saveTask)
                                }}
                            >
                                <AiOutlinePlus size={20} />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                className="text-white border-danger bg-danger"
                                onClick={() => {
                                    var saveTask = clone(datas)
                                    remove(saveTask, (n, key) => key === index)
                                    setFieldValue('material_type', saveTask)
                                }}
                            >
                                <AiOutlineClose size={20} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
