import { combineReducers } from '@reduxjs/toolkit'

import batchDetailed from './batchDetailedSlice'
import collectorDropOff from './collectorDropOffSlice'
import performance from './performanceSlice'
import bagDistribution from './bagDistributionSlice'
import project from './projectSlice'
import monthly from './monthlySlice'

const reducer = combineReducers({
    batchDetailed,
    collectorDropOff,
    performance,
    bagDistribution,
    project,
    monthly,
})

export default reducer
